#contact button{
    width: auto;
    margin-top: 20px;
}
#contact form {
    /* width: 350px; */
}
#contact legend{
    margin-top: 50px;
    text-align: center;
}